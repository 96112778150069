<template>
  <div class="blog">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    />
    <!-- Page Title Area Start -->
    <section class="page-title-area bg-image ptb--80">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="page-title">Blog List</h1>
          </div>
        </div>
      </div>
    </section>
    <!-- Page Title Area End -->
    <!-- Breadcrumb Area Start -->
    <div class="breadcrumb-area mb--60">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li><router-link to="/">Home</router-link></li>
              <li class="current"><span>Blog List</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Breadcrumb Area End -->
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <div class="row">
            <div class="col-md-4" v-for="(newsKey, nkey) in news" :key="nkey">
              <NewsTheme :newsData="newsKey" />
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <aside class="sidebar">
            <div class="widget mb--45">
              <h3 class="widget-title mb--30">Categories</h3>
              <ul class="categories-menu">
                <li
                  v-for="(newsCategoryData, newsCategoryKey) in newsCategory"
                  :key="newsCategoryKey"
                >
                  <i class="fa fa-caret-right"></i>
                  <a
                    href="javascript:void(0)"
                    @click="shortByCategory(newsCategoryData.news_cat_id)"
                    >{{ newsCategoryData.news_cat_name }}</a
                  >
                </li>
              </ul>
            </div>
            <!-- <div class="widget">
                            <h3 class="widget-title mb--30">Tags</h3>
                            <div class="taglist">
                                <a href="news.html">final</a>
                                <a href="news.html">finance</a>
                                <a href="news.html">service</a>
                                <a href="news.html">transport</a>
                                <a href="news.html">website</a>
                            </div>
                        </div> -->
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsTheme from "./../components/HomePage/NewsTheme.vue";
import Loading from "vue-loading-overlay";
export default {
  name: "Blog",
  metaInfo: {
    title: 'My Example App',
    // titleTemplate: '%s - Yay!',
    // htmlAttrs: {
    //     lang: 'en',
    //     amp: true
    // },
    // meta: [
    //     { charset: 'utf-8' },
    //     { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    // ]
  },
  components: {
    NewsTheme,
    Loading,
  },
  created() {
    window.scrollTo(0, 0);
    this.isLoading = true;
    setTimeout(() => {
      this.newsLoad();
      this.isLoading = false;
    }, 2000);
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      news: null,
    };
  },
  computed: {
    newsCategory() {
      return this.$store.state.newsCategory == null
        ? []
        : this.$store.state.newsCategory.filter((data) => {
            return (data.news_cat_status = "1");
          });
    },
  },
  methods: {
    newsLoad() {
      let newsData =
        this.$store.state.news == null
          ? []
          : this.$store.state.news.filter((data) => {
              return (data.news_status = "1");
            });
      this.news = newsData;
    },
    shortByCategory(id) {
      window.scrollTo(0, 0);
      this.isLoading = true;
      let newsData =
        this.$store.state.news == null
          ? []
          : this.$store.state.news.filter((data) => {
              return (data.news_status = "1" && data.news_cat_id === id);
            });
      this.news = newsData;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
  },
  mounted() {
    this.$store.dispatch("getNewsPage");
    this.$store.dispatch("getNewsCategoryPage");
  },
};
</script>
